import React from 'react'
import { Typography, Box, Paper } from '@material-ui/core'

function NightDefault() {
    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography
                        variant="h5"
                        style={{ marginTop: 10, marginBottom: 10 }}
                    >
                        It's night. Waiting for others. Nothing for you to do
                        now.
                    </Typography>
                </Box>
            </Paper>
        </div>
    )
}

export default NightDefault
