import { createStore, compose } from 'redux'
import rootReducer from './rootReducer'
const insideBrowser = typeof window !== 'undefined'
const isLocal = insideBrowser
    ? window.location.href.includes('localhost')
    : false
const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    isLocal
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        : compose

const enhancer = composeEnhancers()
// other store enhancers if any

const store = createStore(rootReducer, enhancer)

export default store
