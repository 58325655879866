import React from 'react'
import { useSelector } from 'react-redux'
import {
    getIsWitch,
    getIsSeer,
    getIsPriest,
    getIsVillageSlut,
} from 'state/game/selectors'
import NightWitch from './nightRemainingViews/NightWitch'
import NightSeer from './nightRemainingViews/NightSeer'
import NightPriest from './nightRemainingViews/NightPriest'
import NightVillageSlut from './nightRemainingViews/NightVillageSlut'

function NightRemaining() {
    const isWitch = useSelector(getIsWitch)
    const isSeer = useSelector(getIsSeer)
    const isPriest = useSelector(getIsPriest)
    const isVillageSlut = useSelector(getIsVillageSlut)

    if (isWitch) {
        return <NightWitch />
    }

    if (isSeer) {
        return <NightSeer />
    }

    if (isPriest) {
        return <NightPriest />
    }

    if (isVillageSlut) {
        return <NightVillageSlut />
    }

    return null // should never happen
}

export default NightRemaining
