import types from './types'

const initialState = {
    isOpen: false,
    type: null,
}

export const ModalTypes = {
    JOIN_GAME: 'JOIN_GAME',
    CREATE_GAME: 'CREATE_GAME',
    OWN_ROLE: 'OWN_ROLE',
    PLAYERS: 'PLAYERS',
    COUPLE: 'COUPLE',
}

function modalReducer(state = initialState, action) {
    if (action.type === types.OPEN_MODAL) {
        return {
            isOpen: true,
            type: action.data,
        }
    }
    if (action.type === types.CLOSE_MODAL) {
        return {
            isOpen: false,
            type: null,
        }
    }
    return state
}

export default modalReducer
