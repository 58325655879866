import React from 'react'
import { AppBar, Toolbar, Button } from '@material-ui/core/'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from 'state/modal/actions'
import { ModalTypes } from 'state/modal/reducer'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { getMyself, getCouple } from 'state/game/selectors'
import OwnRoleModal from './OwnRoleModal'
import PlayersModal from './PlayersModal'
import CoupleModal from './CoupleModal'

function GameFooter() {
    const dispatch = useDispatch()
    const myself = useSelector(getMyself)
    const couple = useSelector(getCouple)

    const hasRole = !!myself.roleLabel

    const showHeart = couple.includes(myself.socketId)

    return (
        <AppBar position="static">
            <OwnRoleModal />
            <PlayersModal />
            <CoupleModal />
            <Toolbar variant="dense">
                {hasRole && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => dispatch(openModal(ModalTypes.OWN_ROLE))}
                        style={{ color: 'white', borderColor: 'white' }}
                    >
                        My Role
                    </Button>
                )}

                {hasRole && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => dispatch(openModal(ModalTypes.PLAYERS))}
                        style={{
                            color: 'white',
                            borderColor: 'white',
                            marginLeft: 10,
                        }}
                    >
                        Players
                    </Button>
                )}

                {showHeart && (
                    <FavoriteIcon
                        onClick={() => dispatch(openModal(ModalTypes.COUPLE))}
                        style={{
                            marginLeft: 10,
                            color: 'red',
                            background: 'white',
                            padding: '3px 10px',
                            cursor: 'pointer',
                        }}
                    />
                )}
            </Toolbar>
        </AppBar>
    )
}

export default GameFooter
