import { Paper, Box, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { getLastDeadPlayers } from 'state/game/selectors'

function LastDeadPlayers() {
    const deadPlayers = useSelector(getLastDeadPlayers)

    if (!deadPlayers.length) {
        return null
    }

    return (
        <div
            style={{
                padding: 20,
            }}
        >
            <Paper>
                <Box p={2}>
                    <Typography variant="h5">Player died:</Typography>

                    {deadPlayers.map(player => (
                        <Typography variant="body1" key={player.socketId}>
                            {player.username}
                        </Typography>
                    ))}
                </Box>
            </Paper>
        </div>
    )
}

export default LastDeadPlayers
