import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    getRoundState,
    getIsAmor,
    getIsWerewolf,
    getIsRemainingNightRole,
    getIsActiveHunter,
} from 'state/game/selectors'
import constants from 'state/game/constants'
import RoleSelection from './RoleSelection'
import NightAmor from './NightAmor'
import NightWerewolf from './NightWerewolf'
import NightRemaining from './NightRemaining'
import DayHunter from './DayHunter'
import DayNominate from './DayNominate'
import DayVoting from './DayVoting'
import GameEnd from './GameEnd'
import NightDefault from './NightDefault'
import { Paper, Typography, Box } from '@material-ui/core'
import { getAmIAlive } from '../../state/game/selectors'

const {
    ROLE_SELECTION,
    NIGHT_AMOR,
    NIGHT_WEREWOLF,
    NIGHT_REMAINING,
    HUNTER_SKILL,
    DAY_NOMINATE,
    DAY_VOTING,
    GAME_END,
} = constants.ROUND_STATES
const nightStates = [NIGHT_AMOR, NIGHT_WEREWOLF, NIGHT_REMAINING]

function RoundStates() {
    const roundState = useSelector(getRoundState)
    const isAmor = useSelector(getIsAmor)
    const isActiveHunter = useSelector(getIsActiveHunter)
    const isWerewolf = useSelector(getIsWerewolf)
    const isRemainingNightRole = useSelector(getIsRemainingNightRole)
    const isNight = nightStates.includes(roundState)
    const amIAlive = useSelector(getAmIAlive)

    useEffect(() => {
        document.querySelector('html').classList.remove('day')
        document.querySelector('html').classList.remove('night')
        document.querySelector('html').classList.add(isNight ? 'night' : 'day')
    }, [isNight])

    if (!amIAlive && isNight) {
        return (
            <div style={{ padding: 20 }}>
                <Paper style={{ marginBottom: 20 }}>
                    <Box p={2}>
                        <Typography variant="h5">
                            You're dead. Nothing for you to do.
                        </Typography>
                    </Box>
                </Paper>
            </div>
        )
    }

    if (roundState === ROLE_SELECTION) {
        return <RoleSelection />
    }

    if (isAmor && roundState === NIGHT_AMOR) {
        return <NightAmor />
    }

    if (isWerewolf && roundState === NIGHT_WEREWOLF) {
        return <NightWerewolf />
    }

    if (isRemainingNightRole && roundState === NIGHT_REMAINING) {
        return <NightRemaining />
    }

    if (isNight) {
        return <NightDefault />
    }

    if (roundState === HUNTER_SKILL) {
        if (isActiveHunter) {
            return <DayHunter />
        }
        return (
            <div style={{ padding: 20 }}>
                <Paper style={{ marginBottom: 20 }}>
                    <Box p={2}>
                        <Typography variant="h5">
                            Hunter died. It's hunters turn now to kill someone.
                        </Typography>
                    </Box>
                </Paper>
            </div>
        )
    }

    if (roundState === DAY_NOMINATE) {
        return <DayNominate />
    }

    if (roundState === DAY_VOTING) {
        return <DayVoting />
    }

    if (roundState === GAME_END) {
        return <GameEnd />
    }

    return <div>Should not happen :/</div>
}

export default RoundStates
