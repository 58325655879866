import React from 'react'
import { useSelector } from 'react-redux'
import {
    getNightFinished,
    getSelectedNight,
    getAlivePlayers,
} from 'state/game/selectors'
import UserButton from 'components/common/UserButton'
import { Typography, Paper, Box, Button } from '@material-ui/core'
import WebSocketService, { wsTypes } from 'WebSocketService'
import { getSelectedLastNight } from '../../../state/game/selectors'

function NightPriest() {
    const nightFinished = useSelector(getNightFinished)
    const selectedNight = useSelector(getSelectedNight)
    const selectedLastNight = useSelector(getSelectedLastNight)
    const alivePlayers = useSelector(getAlivePlayers) // priest can save himself

    if (nightFinished) {
        return (
            <div style={{ padding: 20 }}>
                <Paper style={{ marginBottom: 20 }}>
                    <Box p={2}>
                        <Typography variant="h5">Priest - Sleeping</Typography>
                        <Typography variant="body1">
                            Wait for others to finish their night action
                        </Typography>
                    </Box>
                </Paper>
            </div>
        )
    }

    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Night Priest</Typography>
                    <Typography variant="body1">
                        Choose player you want to protect
                    </Typography>
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    {alivePlayers.map(player => {
                        const isActive = selectedNight === player.socketId
                        const lastTimeProtected =
                            selectedLastNight === player.socketId
                        return (
                            <div
                                key={player.socketId}
                                style={{ marginBottom: 10 }}
                            >
                                <UserButton
                                    onClick={() => {
                                        WebSocketService.emit(
                                            wsTypes.submitSelectedNight,
                                            player.socketId
                                        )
                                    }}
                                    disabled={lastTimeProtected}
                                    isActive={isActive}
                                >
                                    <Typography variant="body1">
                                        {player.username}
                                    </Typography>
                                </UserButton>
                                {lastTimeProtected && (
                                    <React.Fragment>
                                        <Typography variant="caption">
                                            Protected last night
                                        </Typography>
                                    </React.Fragment>
                                )}
                            </div>
                        )
                    })}
                    <div style={{ marginBottom: 10 }}>
                        <UserButton
                            onClick={() =>
                                WebSocketService.emit(
                                    wsTypes.submitSelectedNight,
                                    ''
                                )
                            }
                            isActive={selectedNight === ''}
                        >
                            <Typography variant="body1">Noone</Typography>
                        </UserButton>
                    </div>
                </Box>
            </Paper>

            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => WebSocketService.emit(wsTypes.submitSleep)}
            >
                Sleep
            </Button>
        </div>
    )
}

export default NightPriest
