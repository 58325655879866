import React from 'react'
import { useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import { Link } from 'gatsby'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { getRoomId } from 'state/game/selectors'
import { getUsername, getIsConnected } from 'state/user/selectors'
import SignalWifi3BarIcon from '@material-ui/icons/SignalWifi3Bar'
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff'

const RoomIndicator = () => {
    const roomId = useSelector(getRoomId)
    const username = useSelector(getUsername)
    const connected = useSelector(getIsConnected)

    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <Link to="/" style={{ marginRight: 10 }}>
                    <HomeOutlinedIcon />
                </Link>
                <Typography
                    variant="body2"
                    color="inherit"
                    style={{ flexGrow: 1 }}
                >
                    {username} - Room ID: {roomId}
                </Typography>
                {connected ? (
                    <div
                        style={{
                            background: 'white',
                            display: 'flex',
                            padding: '2px 4px',
                            borderRadius: 4,
                        }}
                    >
                        <SignalWifi3BarIcon style={{ color: 'green' }} />
                    </div>
                ) : (
                    <div
                        style={{
                            background: 'white',
                            display: 'flex',
                            padding: '2px 4px',
                            borderRadius: 4,
                        }}
                    >
                        <SignalWifiOffIcon style={{ color: 'red' }} />
                    </div>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default RoomIndicator
