import React, { useState } from 'react'
import WebSocketService, { wsTypes } from 'WebSocketService'
import { useSelector } from 'react-redux'
import { getAlivePlayers } from 'state/game/selectors'
import { Paper, Typography, Box, Button } from '@material-ui/core'
import UserButton from 'components/common/UserButton'

function DayHunter() {
    const [socketId, setSocketId] = useState('')
    const alivePlayers = useSelector(getAlivePlayers)

    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Hunter</Typography>
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="body1" paragraph>
                        choose player you want to kill or select None
                    </Typography>

                    <div style={{ marginBottom: 10 }}>
                        <UserButton
                            onClick={() => setSocketId('')}
                            isActive={!socketId}
                        >
                            <Typography variant="body1">None</Typography>
                        </UserButton>
                    </div>

                    {alivePlayers.map(player => {
                        return (
                            <div
                                key={player.socketId}
                                style={{ marginBottom: 10 }}
                            >
                                <UserButton
                                    onClick={() => setSocketId(player.socketId)}
                                    isActive={socketId === player.socketId}
                                >
                                    <Typography variant="body1">
                                        {player.username}
                                    </Typography>
                                </UserButton>
                            </div>
                        )
                    })}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() =>
                            WebSocketService.emit(
                                wsTypes.submitHunterDecision,
                                socketId
                            )
                        }
                    >
                        Submit decision
                    </Button>
                </Box>
            </Paper>
        </div>
    )

    return (
        <div>
            hunter: choose player you want to kill or select none
            <div>
                <div
                    style={{
                        border: !socketId ? '1px solid black' : 'none',
                    }}
                    onClick={() => setSocketId('')}
                >
                    none
                </div>
                {alivePlayers.map(player => (
                    <div
                        onClick={() => setSocketId(player.socketId)}
                        style={{
                            border:
                                socketId === player.socketId
                                    ? '1px solid black'
                                    : 'none',
                        }}
                        key={player.socketId}
                    >
                        {player.username}
                    </div>
                ))}
            </div>
            <button
                onClick={() => {
                    WebSocketService.emit(
                        wsTypes.submitHunterDecision,
                        socketId
                    )
                }}
            >
                submit decision
            </button>
        </div>
    )
}

export default DayHunter
