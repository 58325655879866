import React from 'react'
import { getPlayers, getMyself, getAlivePlayers } from 'state/game/selectors'
import { useSelector } from 'react-redux'
import constants from 'state/game/constants'
import WebSocketService, { wsTypes } from 'WebSocketService'
import UserButton from 'components/common/UserButton'
import { Typography, Paper, Box, Button } from '@material-ui/core'

const { NOONE } = constants

function DayNominate() {
    const myself = useSelector(getMyself)
    const players = useSelector(getPlayers)
    const playersDontWantToNominate = players.filter(
        player => player.isAlive && player.nominateSelection === NOONE
    )
    const playersWaitingFor = players
        .filter(player => player.isAlive && !player.hasNominated)
        .filter(p => !p.nominateSelection)

    const nooneSelected = myself.nominateSelection === constants.NOONE

    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Nomination</Typography>
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="body1">
                        Players who havent decided:
                    </Typography>
                    {playersWaitingFor.map(player => (
                        <Typography
                            variant="body1"
                            key={player.socketId}
                            style={{ display: 'inline-block', marginRight: 10 }}
                        >
                            {player.username}
                        </Typography>
                    ))}
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="body1">
                        Players who dont want to nominate:
                    </Typography>
                    {playersDontWantToNominate.map(player => (
                        <Typography
                            variant="body1"
                            key={player.socketId}
                            style={{ display: 'inline-block', marginRight: 10 }}
                        >
                            {player.username}
                        </Typography>
                    ))}
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="body1">
                        Choose "no one" or player you want to nominate
                    </Typography>
                    <UserButton
                        isActive={nooneSelected}
                        style={{ marginBottom: 10 }}
                        onClick={() =>
                            WebSocketService.emit(
                                wsTypes.submitNomination,
                                NOONE
                            )
                        }
                    >
                        <Typography variant="body1">No one</Typography>
                    </UserButton>

                    {players.map(player => (
                        <UserButton
                            onClick={() => {
                                player.isAlive &&
                                    !player.wasNominated &&
                                    WebSocketService.emit(
                                        wsTypes.submitNomination,
                                        player.socketId
                                    )
                            }}
                            disabled={!player.isAlive || player.wasNominated}
                            style={{ marginBottom: 10 }}
                            key={player.socketId}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    textDecoration: player.isAlive
                                        ? 'none'
                                        : 'line-through',
                                }}
                            >
                                {player.username}{' '}
                                {!!player.wasNominated && (
                                    <span style={{ textTransform: 'none' }}>
                                        nominated already
                                    </span>
                                )}
                            </Typography>
                        </UserButton>
                    ))}
                </Box>
            </Paper>
        </div>
    )

    return (
        <div>
            {myself.isAlive && !myself.hasNominated && (
                <div>
                    choose noone or player you want to nominate <br />
                    <button
                        onClick={() =>
                            WebSocketService.emit(
                                wsTypes.submitNomination,
                                NOONE
                            )
                        }
                    >
                        noone
                    </button>
                    <div>
                        players:{' '}
                        {players.map(player => (
                            <div
                                onClick={() => {
                                    player.isAlive &&
                                        !player.wasNominated &&
                                        WebSocketService.emit(
                                            wsTypes.submitNomination,
                                            player.socketId
                                        )
                                }}
                                style={{
                                    textDecoration: player.isAlive
                                        ? 'none'
                                        : 'line-through',
                                }}
                                key={player.socketId}
                            >
                                {player.username}{' '}
                                {!!player.wasNominated && (
                                    <span>nominated already</span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DayNominate
