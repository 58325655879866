import React from 'react'
import { Dialog, Paper, Grid, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getModalInfo } from 'state/modal/selectors'
import { closeModal } from 'state/modal/actions'
import { ModalTypes } from 'state/modal/reducer'
import useComponentTranslation from 'hooks/useComponentTranslation'
import { getMyself } from 'state/game/selectors'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import constants from 'state/game/constants'
import { getCouple, getPlayers } from '../../state/game/selectors'

function CoupleModal() {
    const dispatch = useDispatch()
    const { t } = useComponentTranslation('roles.names')

    const players = useSelector(getPlayers)
    const modalInfo = useSelector(getModalInfo)
    const couple = useSelector(getCouple)

    if (!modalInfo.isOpen || modalInfo.type !== ModalTypes.COUPLE) {
        return null
    }

    const p1 = players.find(p => p.socketId === couple[0])
    const p2 = players.find(p => p.socketId === couple[1])

    return (
        <Dialog
            open
            data-e2e
            onClose={() => dispatch(closeModal())}
            style={{ boxShadow: 'none' }}
        >
            <Paper style={{ padding: 20 }} elevation={0}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Typography variant="body1">
                        You 2 are in love with each other. If one of you dies
                        the other player dies as well.
                    </Typography>
                    <Typography variant="h5">
                        {p1.username} {t(p1.roleLabel)}
                    </Typography>
                    <Typography variant="h5">
                        {p2.username} {t(p2.roleLabel)}
                    </Typography>
                </Grid>
                <div style={{ textAlign: 'right' }}>
                    <CloseOutlinedIcon onClick={() => dispatch(closeModal())} />
                </div>
            </Paper>
        </Dialog>
    )
}

export default CoupleModal
