import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Button, Typography, Box } from '@material-ui/core'
import { getRoleSetup, getPlayers } from 'state/game/selectors'
import constants from 'state/game/constants'
import WebSocketService, { wsTypes } from 'WebSocketService'
import RolePile from 'components/rolePile/RolePile'
import useComponentTranslation from 'hooks/useComponentTranslation'
import { getIsHost } from 'state/user/selectors'

function getNumber(rawNumber, single, label) {
    if (rawNumber < 0) {
        return 0
    }
    if (rawNumber > 16) {
        return 16
    }
    if (rawNumber > 1 && single) {
        return 1
    }
    if (label === constants.ROLES.WEREWOLF.label && rawNumber < 1) {
        return 1
    }
    return rawNumber
}

function RoleSelection() {
    const { t: translateRole } = useComponentTranslation('roles.names')
    const players = useSelector(getPlayers)
    const roleSetup = useSelector(getRoleSetup)
    const isHost = useSelector(getIsHost)

    const totalNumber = Object.keys(roleSetup).reduce((acc, curr) => {
        return (acc += roleSetup[curr])
    }, 0)

    const allRoles = constants.ROLES
    const roles = Object.keys(allRoles)
        .sort()
        .map(roleKey => allRoles[roleKey])

    return (
        <Box p={1}>
            <Box textAlign="center" style={{ marginBottom: 16 }}>
                <Typography variant="h5" style={{ marginBottom: 10 }}>
                    Role setup
                </Typography>
                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1">
                        Total: {`${totalNumber} / ${players.length}`}
                    </Typography>
                    {isHost && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                marginLeft: 20,
                            }}
                            onClick={() => {
                                if (totalNumber !== players.length) {
                                    alert('numbers need to match')
                                    return
                                }
                                WebSocketService.emit(wsTypes.initNight)
                            }}
                        >
                            Start Game!
                        </Button>
                    )}
                </Grid>
            </Box>

            <Grid container spacing={2}>
                {roles.map(role => {
                    const number = roleSetup[role.label] || 0
                    return (
                        <Grid item key={role.label} xs={12} md={6} lg={3}>
                            <RolePile
                                canEdit={isHost}
                                roleName={translateRole(role.label)}
                                imageName={role.img}
                                number={number}
                                onChange={newNumber => {
                                    const number = getNumber(
                                        newNumber || 0,
                                        role.single,
                                        role.label
                                    )

                                    WebSocketService.emit(
                                        wsTypes.updateRoleSetup,
                                        {
                                            label: role.label,
                                            value: number,
                                        }
                                    )
                                }}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default RoleSelection
