import types from './types'

export const setRoomId = roomId => ({
    type: types.SET_ROOM_ID,
    data: roomId,
})

/**
{
    hostId: string(socketId),
    players: [{
        username: string,
        socketId: string,
        connected: boolean
    }],
    view: string, // [start, lobby, mainView]
    roomId,
} - gameState
 */
export const updateGameState = gameState => ({
    type: types.UPDATE_GAME_STATE,
    data: gameState,
})
