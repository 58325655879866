import React from 'react'
import { Grid, Paper, Box, Typography, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

function RolePile({ roleName, imageName, number, onChange, canEdit }) {
    return (
        <React.Fragment>
            <Paper style={{ zIndex: 16, position: 'relative' }}>
                <Box p={1}>
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item>
                            <img
                                style={{
                                    width: 50,
                                    opacity: number > 0 ? '1' : '0.3',
                                }}
                                src={`/roles/${imageName}.png`}
                                alt={roleName}
                            />
                        </Grid>
                        <Grid container item justify="center">
                            <Grid item>
                                <Typography variant="h6">{roleName}</Typography>
                            </Grid>
                            <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                            >
                                {canEdit && (
                                    <Grid item>
                                        <IconButton
                                            onClick={() => onChange(number - 1)}
                                        >
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant="h5">
                                        {number}
                                    </Typography>
                                </Grid>
                                {canEdit && (
                                    <Grid item>
                                        <IconButton
                                            onClick={() => onChange(number + 1)}
                                        >
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            {Array.from({ length: number - 1 }).map((val, i) => (
                <Paper
                    key={i}
                    style={{
                        position: 'relative',
                        height: 10,
                        margin: 'auto',
                        marginTop: -2,
                        zIndex: 15 - i,
                        width: '97%',
                        width: `${97 - i * 3}%`,
                    }}
                ></Paper>
            ))}
        </React.Fragment>
    )
}

export default RolePile
