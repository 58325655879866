import React from 'react'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getGameView } from 'state/game/selectors'
import Start from 'components/views/Start'
import Lobby from 'components/views/Lobby'
import RoundStates from 'components/roundStates/RoundStates'
import LastDeadPlayers from 'components/lastDeadPlayers/LastDeadPlayers'
import RoomIndicator from 'components/roomIndicator/RoomIndicator'
import GameFooter from './GameFooter'
import OwnRoleModal from './OwnRoleModal'

function MainGame() {
    const gameView = useSelector(getGameView)

    if (gameView === 'start') {
        return <Start />
    }

    if (gameView === 'lobby') {
        return <Lobby />
    }

    if (gameView === 'mainView') {
        return (
            <Grid
                container
                direction="column"
                justify="space-between"
                wrap="nowrap"
                style={{ height: '100vh' }}
            >
                <div style={{ overflow: 'auto' }}>
                    <RoomIndicator />
                    <LastDeadPlayers />
                    <RoundStates />
                </div>
                <GameFooter />
            </Grid>
        )
    }

    return null
}

export default MainGame
