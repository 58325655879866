import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Paper, Box, Button } from '@material-ui/core'
import {
    getWerewolfVictim,
    getHealDecision,
    getHasHealPotion,
    getHasPoisonPotion,
    getAlivePlayers,
    getSelectedNight,
    getNightFinished,
} from 'state/game/selectors'
import UserButton from 'components/common/UserButton'
import { getSocketId } from 'state/user/selectors'
import WebSocketService, { wsTypes } from 'WebSocketService'

function NightWitch() {
    const selectedNight = useSelector(getSelectedNight)
    const nightFinished = useSelector(getNightFinished)
    const victim = useSelector(getWerewolfVictim)
    const ownSocketId = useSelector(getSocketId)
    const alivePlayers = useSelector(getAlivePlayers).filter(
        player => player.socketId !== ownSocketId
    )
    const healDecision = useSelector(getHealDecision)
    const hasHeal = useSelector(getHasHealPotion)
    const hasPoison = useSelector(getHasPoisonPotion)

    if (nightFinished) {
        return (
            <div style={{ padding: 20 }}>
                <Paper style={{ marginBottom: 20 }}>
                    <Box p={2}>
                        <Typography variant="h5">
                            Night Witch - sleeping
                        </Typography>
                        <Typography variant="body1">
                            Wait for others to finish their night action
                        </Typography>
                    </Box>
                </Paper>
            </div>
        )
    }

    const victimText = victim
        ? `${victim.username} is the target this night`
        : 'There is no target this night'

    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Night Witch</Typography>
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    {hasHeal ? (
                        <>
                            <Typography variant="body1">
                                Heal potion: 1
                            </Typography>
                            <Typography variant="body1">
                                {victimText}
                            </Typography>
                            {!!victim && (
                                <>
                                    <Typography variant="body1">
                                        Wanna heal target?
                                    </Typography>
                                    <div style={{ marginBottom: 10 }}>
                                        <UserButton
                                            onClick={() =>
                                                WebSocketService.emit(
                                                    wsTypes.submitWitchHeal,
                                                    'yes'
                                                )
                                            }
                                            isActive={healDecision === 'yes'}
                                        >
                                            Yes
                                        </UserButton>
                                    </div>
                                    <UserButton
                                        onClick={() =>
                                            WebSocketService.emit(
                                                wsTypes.submitWitchHeal,
                                                'no'
                                            )
                                        }
                                        isActive={healDecision === 'no'}
                                    >
                                        No
                                    </UserButton>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography variant="body1">
                                Heal potion: 0
                            </Typography>
                        </>
                    )}
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    {hasPoison ? (
                        <>
                            <Typography variant="body1">
                                Poison potion: 1
                            </Typography>
                            <Typography variant="body1">
                                If you want to use your potion to kill, choose a
                                player:
                            </Typography>
                            <div style={{ marginBottom: 10 }}>
                                <UserButton
                                    onClick={() =>
                                        WebSocketService.emit(
                                            wsTypes.submitSelectedNight,
                                            ''
                                        )
                                    }
                                    isActive={selectedNight === ''}
                                >
                                    <Typography variant="body1">
                                        Noone
                                    </Typography>
                                </UserButton>
                            </div>
                            {alivePlayers.map(player => {
                                return (
                                    <div
                                        key={player.socketId}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <UserButton
                                            onClick={() =>
                                                WebSocketService.emit(
                                                    wsTypes.submitSelectedNight,
                                                    player.socketId
                                                )
                                            }
                                            isActive={
                                                selectedNight ===
                                                player.socketId
                                            }
                                        >
                                            <Typography variant="body1">
                                                {player.username}
                                            </Typography>
                                        </UserButton>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <Typography variant="body1">
                            Poison potion: 0
                        </Typography>
                    )}
                </Box>
            </Paper>

            {!hasHeal && !hasPoison && (
                <Paper style={{ marginBottom: 20 }}>
                    <Box p={2}>
                        <Typography variant="h5">
                            No potions left. Wait for other finishing their
                            night actions
                        </Typography>
                    </Box>
                </Paper>
            )}

            {(hasHeal || hasPoison) && (
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => WebSocketService.emit(wsTypes.submitSleep)}
                >
                    Sleep
                </Button>
            )}
        </div>
    )
}

export default NightWitch
