import { useEffect, useState } from 'react'
import { parse } from 'query-string'

const insideBrowser = typeof window !== 'undefined'

export default function useQueryParams() {
    const { search } = insideBrowser ? window.location : { search: {} }
    const [queryParams, setQueryParams] = useState(parse(search))

    useEffect(() => {
        setQueryParams(parse(search))
    }, [search])

    return queryParams
}
