import React from 'react'
import { useSelector } from 'react-redux'
import { getWinningTeam } from 'state/game/selectors'
import { Typography, Box, Paper, Button } from '@material-ui/core'

function GameEnd() {
    const winningTeam = useSelector(getWinningTeam)
    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography
                        variant="h5"
                        style={{ marginTop: 10, marginBottom: 10 }}
                    >
                        Game End
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Winning team: {winningTeam}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => (window.location.href = '/game')}
                    >
                        New game
                    </Button>
                </Box>
            </Paper>
        </div>
    )
}

export default GameEnd
