import React from 'react'
import { Dialog, Paper, Grid, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getModalInfo } from 'state/modal/selectors'
import { closeModal } from 'state/modal/actions'
import { ModalTypes } from 'state/modal/reducer'
import { getMyself } from 'state/game/selectors'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import constants from 'state/game/constants'
import { getPlayers } from '../../state/game/selectors'

const { ROLES } = constants

function PlayersModal() {
    const dispatch = useDispatch()
    const players = useSelector(getPlayers)
    const modalInfo = useSelector(getModalInfo)
    const myself = useSelector(getMyself)

    const werewolfLabel = ROLES.WEREWOLF.label

    const amIWerewolf = myself.roleLabel === werewolfLabel

    if (!modalInfo.isOpen || modalInfo.type !== ModalTypes.PLAYERS) {
        return null
    }

    return (
        <Dialog
            open
            data-e2e
            onClose={() => dispatch(closeModal())}
            style={{ boxShadow: 'none' }}
        >
            <Paper style={{ padding: 20 }} elevation={0}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Typography variant="h5">Players:</Typography>
                    {players.map(player => {
                        const isPlayerWerewolf =
                            player.roleLabel === werewolfLabel
                        const showWerewolf = amIWerewolf && isPlayerWerewolf
                        return (
                            <Typography
                                key={player.socketId}
                                style={{
                                    textDecoration: player.isAlive
                                        ? 'none'
                                        : 'line-through',
                                }}
                            >
                                {player.username} {showWerewolf && 'Werewolf'}
                            </Typography>
                        )
                    })}
                </Grid>
                <div style={{ textAlign: 'right' }}>
                    <CloseOutlinedIcon onClick={() => dispatch(closeModal())} />
                </div>
            </Paper>
        </Dialog>
    )
}

export default PlayersModal
