import { combineReducers } from 'redux'
import userReducer from './user/reducer'
import gameReducer from './game/reducer'
import loadingReducer from './loading/reducer'
import modalReducer from './modal/reducer'

export default combineReducers({
    user: userReducer,
    game: gameReducer,
    loading: loadingReducer,
    modal: modalReducer,
})
