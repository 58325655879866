import types from './types'

export function openModal(type) {
    return {
        type: types.OPEN_MODAL,
        data: type,
    }
}

export function closeModal() {
    return {
        type: types.CLOSE_MODAL,
    }
}
