import React from 'react'
import { useSelector } from 'react-redux'
import { getPlayers } from 'state/game/selectors'
import { List, ListItem, Typography, Button, Grid } from '@material-ui/core'
import RoomIndicator from 'components/roomIndicator/RoomIndicator'
import { getIsHost } from 'state/user/selectors'
import WebSocketService, { wsTypes } from 'WebSocketService'
import { useTranslation } from 'react-i18next'

function h(key) {
    return `components.views.Lobby.${key}`
}

function Lobby() {
    const players = useSelector(getPlayers)
    const { t } = useTranslation()
    const isHost = useSelector(getIsHost)

    return (
        <Grid container direction="column" alignItems="center">
            <RoomIndicator />
            <List>
                {players.map(player => (
                    <ListItem
                        style={{ justifyContent: 'center' }}
                        key={player.socketId}
                    >
                        <Typography variant="body1">
                            {player.username}
                        </Typography>
                    </ListItem>
                ))}
            </List>
            <br />
            {isHost && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (players.length >= 5) {
                            WebSocketService.emit(wsTypes.start)
                        } else {
                            alert('Total 5 players needed')
                        }
                    }}
                >
                    {t(h('startGame'))}
                </Button>
            )}
        </Grid>
    )
}

export default Lobby
