import types from './types'

const insideBrowser = typeof window !== 'undefined'

const initialState = {
    username: insideBrowser ? localStorage.getItem('username') || '' : '',
    socketId: insideBrowser ? localStorage.getItem('socketId') || '' : '',
    connected: false,
}

function userReducer(state = initialState, action) {
    if (action.type === types.SET_USERNAME) {
        if (insideBrowser) {
            localStorage.setItem('username', action.data)
        }
        return {
            ...state,
            username: action.data,
        }
    }
    if (action.type === types.SET_SOCKET_ID) {
        if (insideBrowser) {
            localStorage.setItem('socketId', action.data)
        }
        return {
            ...state,
            socketId: action.data,
        }
    }
    if (action.type === types.UPDATE_CONNECTION_STATUS) {
        return {
            ...state,
            connected: action.data,
        }
    }
    return state
}

export default userReducer
