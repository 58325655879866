import types from './types'

const initialState = {
    isLoading: false,
}

function loading(state = initialState, action) {
    if (action.type === types.UPDATE_IS_LOADING) {
        return {
            isLoading: action.data,
        }
    }
    return state
}

export default loading
