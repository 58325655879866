import React from 'react'
import { Typography, Paper, Box, Button, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
    getNominatedPlayer,
    getAlivePlayers,
    getMyself,
} from 'state/game/selectors'
import WebSocketService, { wsTypes } from 'WebSocketService'

function DayVoting() {
    const nominatedPlayer = useSelector(getNominatedPlayer)
    const players = useSelector(getAlivePlayers)
    const myself = useSelector(getMyself)
    const nominator = players.find(
        p => p.socketId === nominatedPlayer.nominatedBy
    )
    const missingVotes = players.filter(p => !p.lynchDecision)

    const yesVotes = players.filter(p => p.lynchDecision === 'yes')
    const noVotes = players.filter(p => p.lynchDecision === 'no')

    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Lynch-Voting</Typography>
                    <Typography variant="body1">
                        Nominated player: {nominatedPlayer.username} by{' '}
                        {nominator.username}
                    </Typography>
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="body1">Havent voted:</Typography>
                    {missingVotes.map(p => (
                        <Typography
                            style={{ marginRight: 10, display: 'inline-block' }}
                            key={p.socketId}
                        >
                            {p.username}
                        </Typography>
                    ))}
                </Box>
            </Paper>

            {myself.isAlive && (
                <Paper>
                    <Box p={2}>
                        <Typography variant="body1">
                            Should {nominatedPlayer.username} be lynched?
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={() =>
                                        WebSocketService.emit(
                                            wsTypes.submitLynchDecision,
                                            'yes'
                                        )
                                    }
                                >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={() =>
                                        WebSocketService.emit(
                                            wsTypes.submitLynchDecision,
                                            'no'
                                        )
                                    }
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )}

            <Grid
                container
                spacing={2}
                justify="space-between"
                style={{ textAlign: 'center', marginTop: 20 }}
            >
                <Grid item xs={6}>
                    <Paper>
                        <Typography variant="body1">
                            Yes ({yesVotes.length})
                        </Typography>
                        {yesVotes.map(p => (
                            <Typography variant="body2" key={p.socketId}>
                                {p.username}
                            </Typography>
                        ))}
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Typography variant="body1">
                            No ({noVotes.length})
                        </Typography>
                        {noVotes.map(p => (
                            <Typography variant="body2" key={p.socketId}>
                                {p.username}
                            </Typography>
                        ))}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default DayVoting
