import io from 'socket.io-client'
import {
    getGameView,
    getRoomId,
    getMyself,
    getIsActiveHunter,
    getRoundState,
} from 'state/game/selectors'
import { updateIsLoading } from 'state/loading/actions'
import store from 'state/store'
import { updateGameState } from 'state/game/actions'
import { setSocketId, updateConnectionStatus } from 'state/user/actions'
import { getSocketId } from 'state/user/selectors'

export const wsTypes = {
    start: 'start',
    moveUp: 'moveUp',
    updateRoleSetup: 'updateRoleSetup',
    initNight: 'initNight',

    submitAmorSelection: 'submitAmorSelection',
    submitWerewolfSelection: 'submitWerewolfSelection',

    submitSelectedNight: 'submitSelectedNight', // for all night remaining chars
    submitSleep: 'submitSleep', // for all night remaining chars

    submitWitchHeal: 'submitWitchHeal',

    submitHunterDecision: 'submitHunterDecision',

    submitNomination: 'submitNomination',
    submitLynchDecision: 'submitLynchDecision',
}

class WebSocketService {
    constructor() {
        this._socket = null

        this.mode = ''
        this.username = ''
        this.roomId = ''

        this.retries = 0
    }

    reconnect() {
        console.log(this.retries)
        if (this.retries >= 3) {
            alert('connection lost. page will reload')
            window.location.href = ''
            return
        }
        setTimeout(() => {
            this.retries += 1
            const roomId = getRoomId(store.getState())
            this.connect('join', this.username, roomId)
        }, 2000)
    }

    /**
     * connects to server and attaches listeners
     * @param {String} mode - create or join
     * @param {String} username
     * @param {String} roomId
     */
    connect(mode, username, roomId) {
        const isLocal = window.location.href.includes('localhost')
        const websocketHost = isLocal
            ? 'http://localhost:52912'
            : 'https://werewolf.uber.space/'

        this._socket = io(websocketHost, {
            query: {
                mode,
                username,
                roomId,
                socketId: getSocketId(store.getState()),
            },
            reconnection: false,
        })

        this.mode = mode
        this.username = username
        this.roomId = roomId

        window.testsocket = this._socket // for debugging

        this._socket.on('connect', () => {
            this.retries = 0
            store.dispatch(updateConnectionStatus(true))

            console.log('connected')
        })

        this._socket.on('connect_failed', () => {
            this.reconnect()
        })
        this._socket.on('connect_error', () => {
            this.reconnect()
        })

        this._socket.on('message', payload => {
            const { backend_type, data } = payload

            store.dispatch(updateIsLoading(false))
            if (backend_type === 'gameState') {
                if (data.roomId) {
                    const insideBrowser = typeof window !== 'undefined'
                    if (insideBrowser) {
                        localStorage.setItem('roomId', data.roomId)
                    }
                }

                store.dispatch(updateGameState(data))
            }

            if (backend_type === 'socketId') {
                store.dispatch(setSocketId(data))
            }

            if (backend_type === 'error') {
                window.alert(data)
            }
        })

        this._socket.on('disconnect', () => {
            const view = getGameView(store.getState())
            // no reconnect on start view
            if (view !== 'start') {
                console.log('disconnected')
                store.dispatch(updateConnectionStatus(false))

                this.reconnect()
            }
        })
    }

    emit(type, data) {
        if (!this._socket) {
            alert('this state should never happen, WebSocketService.emit()')
        }
        const storeState = store.getState()
        const gameView = getGameView(storeState)
        const myself = getMyself(storeState)
        const isActiveHunter = getIsActiveHunter(storeState)

        if (gameView === 'lobby' || myself.isAlive || isActiveHunter) {
            store.dispatch(updateIsLoading(true))

            this._socket.emit('message', {
                client_type: type,
                data,
            })
        }
    }
}

export default new WebSocketService()
