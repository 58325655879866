import React, { useState } from 'react'
import { getSocketId } from 'state/user/selectors'
import {
    getAlivePlayers,
    getSelectedNight,
    getNightFinished,
} from 'state/game/selectors'
import UserButton from 'components/common/UserButton'
import { Typography, Paper, Box, Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import WebSocketService, { wsTypes } from 'WebSocketService'
import constants from 'state/game/constants'

const { WEREWOLF, LYCANTHROPE } = constants.ROLES

function NightSeer() {
    const [selected, setSelected] = useState('')
    const nightFinished = useSelector(getNightFinished)
    const selectedNight = useSelector(getSelectedNight)
    const ownSocketId = useSelector(getSocketId)
    const alivePlayers = useSelector(getAlivePlayers).filter(
        player => player.socketId !== ownSocketId
    )
    const playerToReveal = alivePlayers.find(
        player => player.socketId === selectedNight
    )

    function getGoodOrBad(roleLabel) {
        const badLabels = [WEREWOLF.label, LYCANTHROPE.label]
        const isBad = badLabels.includes(roleLabel)
        return isBad ? 'the bad players' : 'the good players' // nicetohave i18n
    }

    if (nightFinished) {
        return (
            <div style={{ padding: 20 }}>
                <Paper style={{ marginBottom: 20 }}>
                    <Box p={2}>
                        <Typography variant="h5">Seer - Sleeping</Typography>
                        <Typography variant="body1">
                            Wait for others to finish their night action
                        </Typography>
                    </Box>
                </Paper>
            </div>
        )
    }

    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Night Seer</Typography>
                </Box>
            </Paper>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    {!!selectedNight ? (
                        <div>
                            <Typography variant="body1">
                                {!!playerToReveal && (
                                    <div style={{ marginBottom: 10 }}>
                                        {playerToReveal.username} belong to{' '}
                                        {getGoodOrBad(playerToReveal.roleLabel)}
                                    </div>
                                )}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() =>
                                    WebSocketService.emit(wsTypes.submitSleep)
                                }
                            >
                                Sleep
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Typography
                                variant="body1"
                                style={{ marginBottom: 10 }}
                            >
                                Choose player you want to investigate
                            </Typography>
                            {alivePlayers.map(player => {
                                const isActive = selected === player.socketId
                                return (
                                    <div
                                        key={player.socketId}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <UserButton
                                            onClick={() =>
                                                setSelected(player.socketId)
                                            }
                                            isActive={isActive}
                                        >
                                            <Typography variant="body1">
                                                {player.username}
                                            </Typography>
                                        </UserButton>
                                    </div>
                                )
                            })}
                            <div style={{ marginBottom: 10 }}>
                                <UserButton
                                    onClick={() => setSelected(constants.NOONE)}
                                    isActive={selected === constants.NOONE}
                                >
                                    <Typography variant="body1">
                                        Noone
                                    </Typography>
                                </UserButton>
                            </div>

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() =>
                                    WebSocketService.emit(
                                        wsTypes.submitSelectedNight,
                                        selected
                                    )
                                }
                            >
                                Confirm selection
                            </Button>
                        </div>
                    )}
                </Box>
            </Paper>
        </div>
    )
}

export default NightSeer
