import React from 'react'
import { Dialog, Paper, Grid, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getModalInfo } from 'state/modal/selectors'
import { closeModal } from 'state/modal/actions'
import { ModalTypes } from 'state/modal/reducer'
import { getMyself } from 'state/game/selectors'
import constants from 'state/game/constants'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import useComponentTranslation from 'hooks/useComponentTranslation'

const { ROLES } = constants

function OwnRoleModal() {
    const { t: translateRole } = useComponentTranslation('roles.names')
    const { t: translateDescription } = useComponentTranslation(
        'roles.descriptions'
    )
    const dispatch = useDispatch()
    const modalInfo = useSelector(getModalInfo)
    const myself = useSelector(getMyself)

    if (!modalInfo.isOpen || modalInfo.type !== ModalTypes.OWN_ROLE) {
        return null
    }

    return (
        <Dialog
            open
            data-e2e
            onClose={() => dispatch(closeModal())}
            style={{ boxShadow: 'none' }}
        >
            <Paper style={{ padding: 20 }} elevation={0}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Typography variant="h5">
                        {translateRole(myself.roleLabel)}
                    </Typography>
                    <img
                        style={{
                            width: 100,
                        }}
                        src={`/roles/${ROLES[myself.roleLabel].img}.png`}
                        alt={myself.roleLabel}
                    />
                    <Typography variant="body1">
                        {translateDescription(myself.roleLabel)}
                    </Typography>
                </Grid>
                <div style={{ textAlign: 'right' }}>
                    <CloseOutlinedIcon onClick={() => dispatch(closeModal())} />
                </div>
            </Paper>
        </Dialog>
    )
}

export default OwnRoleModal
