import React, { useState } from 'react'
import { Typography, Button, Paper, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getPlayers } from 'state/game/selectors'
import WebSocketService, { wsTypes } from 'WebSocketService'
import UserButton from 'components/common/UserButton'

function NightAmor() {
    const [selected, setSelected] = useState([])
    const players = useSelector(getPlayers)

    function handleClicked(socketId) {
        if (selected.includes(socketId)) {
            return
        }
        setSelected([...selected, socketId].slice(-2))
    }

    function handleSubmit() {
        if (selected.length !== 2) {
            alert('Select 2')
            return
        }
        WebSocketService.emit(wsTypes.submitAmorSelection, selected)
    }
    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Night Armor</Typography>
                    <Typography variant="body1">
                        Select 2 players who should be in love. Submit your
                        selection
                    </Typography>
                </Box>
            </Paper>

            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    {players.map(player => {
                        const isActive = selected.includes(player.socketId)
                        return (
                            <div
                                key={player.socketId}
                                style={{ marginBottom: 10 }}
                            >
                                <UserButton
                                    onClick={() =>
                                        handleClicked(player.socketId)
                                    }
                                    isActive={isActive}
                                >
                                    <Typography variant="body1">
                                        {player.username}
                                    </Typography>
                                </UserButton>
                            </div>
                        )
                    })}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit selection
                    </Button>
                </Box>
            </Paper>
        </div>
    )
}

export default NightAmor
