import { getHostId } from 'state/game/selectors'
import { createSelector } from 'reselect'

export const getUsername = state => state.user.username

/**
 * returns own socketId
 * @param {*} state
 */
export const getSocketId = state => state.user.socketId

export const getIsConnected = state => state.user.connected

export const getIsHost = createSelector(
    [getHostId, getSocketId],
    (hostId, socketId) => hostId === socketId
)
