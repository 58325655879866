import types from './types'

const insideBrowser = typeof window !== 'undefined'

const initialState = {
    roomId: insideBrowser ? localStorage.getItem('roomId') || '' : '',
    view: 'start', // start , lobby, mainView
}

function gameReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_ROOM_ID: {
            if (insideBrowser) {
                localStorage.setItem('roomId', action.data)
            }
            return {
                ...state,
                roomId: action.data,
            }
        }
        case types.UPDATE_GAME_STATE: {
            return {
                ...action.data,
            }
        }
        default: {
            return state
        }
    }
}

export default gameReducer
