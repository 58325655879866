// same as /backend/state/game/constants.js
export default {
    ROUND_STATES: {
        ROLE_SELECTION: 'ROLE_SELECTION', // everyone will see roles setup, host can change, players directly see changes
        NIGHT_AMOR: 'NIGHT_AMOR',
        NIGHT_WEREWOLF: 'NIGHT_WEREWOLF',
        NIGHT_REMAINING: 'NIGHT_REMAINING',

        HUNTER_SKILL: 'HUNTER_SKILL',
        DAY_NOMINATE: 'DAY_NOMINATE',
        DAY_VOTING: 'DAY_VOTING',

        GAME_END: 'GAME_END', // after 1 team has won because of winning conditions
    },
    ROLES: {
        AMOR: {
            balancing: -2,
            label: 'AMOR',
            single: true,
            img: 'amor',
        },
        HUNTER: {
            balancing: 3,
            label: 'HUNTER',
            single: false,
            img: 'hunter',
        },
        LYCANTHROPE: {
            balancing: -2,
            label: 'LYCANTHROPE',
            single: false,
            img: 'lycanthrope',
        },
        PRIEST: {
            balancing: 4,
            label: 'PRIEST',
            single: false,
            img: 'priest',
        },
        SEER: {
            balancing: 7,
            label: 'SEER',
            single: false,
            img: 'seer',
        },
        TOUGH_GUY: {
            balancing: 2,
            label: 'TOUGH_GUY',
            single: false,
            img: 'tough-guy',
        },
        VILLAGER: {
            balancing: 1,
            label: 'VILLAGER',
            single: false,
            img: 'villager1',
        },
        VILLAGE_SLUT: {
            balancing: 2,
            label: 'VILLAGE_SLUT',
            single: false,
            img: 'dream-girl',
        },
        WEREWOLF: {
            balancing: -6,
            label: 'WEREWOLF',
            single: false,
            img: 'werewolf',
        },
        WITCH: {
            balancing: 4,
            label: 'WITCH',
            single: true,
            img: 'witch',
        },
    },
    NOONE: '-noone-',
}
