import React from 'react'
import { Button } from '@material-ui/core'

function UserButton({ isActive, style, ...rest }) {
    return (
        <Button
            variant="contained"
            fullWidth
            {...rest}
            style={{
                background: isActive ? '#B50000' : '#4A5B6E',
                color: 'white',
                ...style,
            }}
        >
            {rest.children}
        </Button>
    )
}

export default UserButton
