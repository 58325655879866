import React from 'react'
import SEO from 'components/gatsby/seo'
import MainGame from 'components/mainGame/MainGame'
import { Provider } from 'react-redux'
import store from 'state/store'
import Layout from 'components/gatsby/layout'
import '../i18n'

const Game = () => {
    return (
        <Layout blank>
            <SEO title="Game" />
            <Provider store={store}>
                <MainGame />
            </Provider>
        </Layout>
    )
}

export default Game
