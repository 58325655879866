import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { Button, Grid } from '@material-ui/core'
import { openModal } from 'state/modal/actions'
import ModalCreateGame from './startModals/ModalCreateGame'
import ModalJoinGame from './startModals/ModalJoinGame'
import WebSocketService from 'WebSocketService'
import { ModalTypes } from 'state/modal/reducer'
import useQueryParams from '../../hooks/useQueryParams'

const insideBrowser = typeof window !== 'undefined'

const Start = () => {
    // const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const { name, roomId } = useQueryParams()

    useEffect(() => {
        if (name && roomId) {
            if (insideBrowser) {
                localStorage.setItem('username', name)
            }
            WebSocketService.connect('join', name, roomId)
        }
    }, [name, roomId])

    // function setLanguage(lang) {
    //     i18n.changeLanguage(lang)
    //     if (insideBrowser) {
    //         localStorage.setItem('lang', lang)
    //     }
    // }
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{
                margin: 'auto',
                maxWidth: 400,
                height: '100vh',
                backgroundImage: "url('/background.jpg')",
                backgroundSize: 'cover',
            }}
        >
            <ModalCreateGame />
            <ModalJoinGame />
            <Link to="/">
                <h1
                    style={{
                        background: 'black',
                        color: 'white',
                        padding: '2px 12px 6px',
                        fontSize: 28,
                    }}
                >
                    werewolf-night.com
                </h1>
            </Link>

            <Button
                onClick={() => dispatch(openModal(ModalTypes.JOIN_GAME))}
                variant="contained"
                color="primary"
                style={{ marginTop: 40, marginBottom: 20 }}
            >
                Join game
            </Button>

            <Button
                onClick={() => dispatch(openModal(ModalTypes.CREATE_GAME))}
                variant="contained"
                color="primary"
            >
                Create game
            </Button>

            {/* language selection, maybe never do it any only offer english */}
            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 40,
                }}
            >
                <div
                    onClick={() => setLanguage('de')}
                    style={{ marginRight: 20 }}
                >
                    de
                </div>
                <div onClick={() => setLanguage('en')}>en</div>
            </div> */}
        </Grid>
    )
}

export default Start
