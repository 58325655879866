import { createSelector } from 'reselect'
import constants from './constants'

const {
    AMOR,
    WEREWOLF,
    SEER,
    WITCH,
    PRIEST,
    VILLAGE_SLUT,
    HUNTER,
} = constants.ROLES

export const getRoomId = state => state.game.roomId

export const getGameView = state => state.game.view

export const getHostId = state => state.game.hostId

export const getRoundState = state => state.game.roundState

export const getRoleSetup = state => state.game.roleSetup

const getSocketId = state => state.user.socketId // also in user/selectors duplicate here to avoid circular dependency

// players ========================================================
export const getPlayers = state => state.game.players

export const getAlivePlayers = createSelector([getPlayers], players =>
    players.filter(player => player.isAlive)
)

export const getMyself = createSelector(
    [getSocketId, getPlayers],
    (socketId, players) => {
        return players.find(player => player.socketId === socketId)
    }
)

export const getAmIAlive = createSelector([getMyself], myself => myself.isAlive)
// roles ========================================================
export const getIsAmor = createSelector(
    [getMyself],
    myself => myself.roleLabel === AMOR.label
)

const getActiveHunters = state => state.game.activeHunters
export const getIsActiveHunter = createSelector(
    [getMyself, getActiveHunters],
    (myself, hunters) => {
        if (!hunters || !hunters.length) {
            return false
        }
        return (
            myself.roleLabel === HUNTER.label && hunters[0] === myself.socketId
        )
    }
)

export const getIsWerewolf = createSelector(
    [getMyself],
    myself => myself.roleLabel === WEREWOLF.label
)

export const getWereWolves = createSelector([getAlivePlayers], players =>
    players.filter(player => player.roleLabel === WEREWOLF.label)
)

export const getIsRemainingNightRole = createSelector([getMyself], myself => {
    return [SEER.label, WITCH.label, PRIEST.label, VILLAGE_SLUT.label].some(
        label => label === myself.roleLabel
    )
})

export const getIsWitch = createSelector(
    [getMyself],
    myself => myself.roleLabel === WITCH.label
)

export const getIsSeer = createSelector(
    [getMyself],
    myself => myself.roleLabel === SEER.label
)

export const getIsPriest = createSelector(
    [getMyself],
    myself => myself.roleLabel === PRIEST.label
)

export const getIsVillageSlut = createSelector(
    [getMyself],
    myself => myself.roleLabel === VILLAGE_SLUT.label
)
// other ========================================================
export const getWerewolfVictim = createSelector(
    [getWereWolves, getAlivePlayers],
    (werewolves, alivePlayers) => {
        const votes = werewolves.map(w => w.selectedNight)
        const unique = [...new Set(votes)]
        const socketId = unique.includes(constants.NOONE) ? '' : unique[0]
        return alivePlayers.find(player => player.socketId === socketId)
    }
)

export const getSelectedNight = createSelector(
    [getMyself],
    myself => myself.selectedNight
)

export const getSelectedLastNight = createSelector(
    [getMyself],
    myself => myself.selectedLastNight
)

export const getNightFinished = createSelector(
    [getMyself],
    myself => myself.nightFinished
)

export const getHealDecision = createSelector(
    [getMyself],
    myself => myself.healDecision
)

export const getHasHealPotion = createSelector(
    [getMyself],
    myself => myself.hasHealPotion
)

export const getHasPoisonPotion = createSelector(
    [getMyself],
    myself => myself.hasPoisonPotion
)

const getDeadSocketIds = state => state.game.lastDeadPlayers
export const getLastDeadPlayers = createSelector(
    [getPlayers, getDeadSocketIds],
    (players, deadSockets) => {
        const deadPlayers = deadSockets.map(socketId =>
            players.find(player => player.socketId === socketId)
        )
        return deadPlayers
    }
)

export const getCouple = state => state.game.couple

const getNominatedSocket = state => state.game.currentNominatedPlayer
export const getNominatedPlayer = createSelector(
    [getAlivePlayers, getNominatedSocket],
    (players, nominatedSocketId) =>
        players.find(p => p.socketId === nominatedSocketId)
)

export const getWinningTeam = state => state.game.winningTeam
