import React from 'react'
import { useSelector } from 'react-redux'
import { getAlivePlayers, getWereWolves } from 'state/game/selectors'
import constants from 'state/game/constants'
import { Typography, Paper, Box } from '@material-ui/core'
import WebSocketService, { wsTypes } from 'WebSocketService'
import UserButton from 'components/common/UserButton'

function NightWerewolf() {
    const players = useSelector(getAlivePlayers)
    const werewolves = useSelector(getWereWolves)
    const playersToKill = players.filter(
        player => player.roleLabel !== constants.ROLES.WEREWOLF.label
    )

    const notVotedYet = werewolves.filter(werewolf => !werewolf.selectedNight)

    const playerWerewolfMapping = werewolves.reduce((prev, curr) => {
        const selection = curr.selectedNight
        if (!selection) {
            return prev
        }
        prev[selection] = prev[selection] || []
        prev[selection].push(curr.username)
        return prev
    }, {})

    // wolves can also not kill during night, may be useful when 2 wolves and a villager is alive where 1 wolf and villager is a couple
    const noneMapping = playerWerewolfMapping[constants.NOONE]
    return (
        <div style={{ padding: 20 }}>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="h5">Night Werewolves</Typography>
                    <Typography variant="body1">
                        Agree on one victim. If you dont agree because someone
                        dont want to kill: noone will be killed
                    </Typography>
                </Box>
            </Paper>
            <Paper style={{ marginBottom: 20 }}>
                <Box p={2}>
                    <Typography variant="body1">Missing selection:</Typography>
                    <Typography
                        variant="body1"
                        style={{ marginRight: 4, display: 'inline-block' }}
                    >
                        {notVotedYet.map(c => c.username).join(', ')}
                    </Typography>
                </Box>
            </Paper>
            <Paper>
                <Box p={2}>
                    <Typography
                        variant="caption"
                        style={{ marginBottom: 10 }}
                        display="block"
                    >
                        Villagers
                    </Typography>
                    {playersToKill.map(player => {
                        const mappedWerewolves =
                            playerWerewolfMapping[player.socketId]
                        return (
                            <div
                                key={player.socketId}
                                style={{ marginBottom: 10 }}
                            >
                                <UserButton
                                    onClick={() => {
                                        WebSocketService.emit(
                                            wsTypes.submitWerewolfSelection,
                                            player.socketId
                                        )
                                    }}
                                    isActive={mappedWerewolves?.length}
                                >
                                    <Typography variant="body1">
                                        {player.username}
                                    </Typography>
                                </UserButton>
                                {mappedWerewolves && (
                                    <React.Fragment>
                                        <Typography variant="caption">
                                            Voted by:
                                        </Typography>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                marginLeft: 4,
                                            }}
                                        >
                                            {mappedWerewolves.join(', ')}
                                        </Typography>
                                    </React.Fragment>
                                )}
                            </div>
                        )
                    })}
                    <UserButton
                        variant="body1"
                        onClick={() => {
                            WebSocketService.emit(
                                wsTypes.submitWerewolfSelection,
                                constants.NOONE
                            )
                        }}
                        isActive={noneMapping?.length}
                    >
                        Noone
                    </UserButton>
                    {noneMapping && (
                        <Typography>
                            <Typography variant="caption">
                                Dont want to kill:
                            </Typography>
                            <Typography
                                style={{
                                    display: 'inline-block',
                                    marginRight: 4,
                                }}
                            >
                                {noneMapping.join(', ')}
                            </Typography>
                        </Typography>
                    )}
                </Box>
            </Paper>
        </div>
    )
}

export default NightWerewolf
