import React from 'react'
import {
    Dialog,
    TextField,
    Paper,
    Grid,
    Button,
    Typography,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getModalInfo } from 'state/modal/selectors'
import { closeModal } from 'state/modal/actions'
import { getUsername } from 'state/user/selectors'
import { setUsername } from 'state/user/actions'
import { useTranslation } from 'react-i18next'
import { updateIsLoading } from 'state/loading/actions'
import WebSocketService from 'WebSocketService'
import { getIsLoading } from 'state/loading/selectors'
import { ModalTypes } from 'state/modal/reducer'

function h(key) {
    return `components.views.startModals.ModalCreateGame.${key}`
}

function ModalCreateGame() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const modalInfo = useSelector(getModalInfo)
    const username = useSelector(getUsername)
    const isLoading = useSelector(getIsLoading)

    if (!modalInfo.isOpen || modalInfo.type !== ModalTypes.CREATE_GAME) {
        return null
    }

    const createRoom = username => {
        if (!username) {
            alert(t(h('enterUser')))
            return
        }

        if (username.length > 15) {
            alert(t(h('useShorterName')))
            return
        }

        dispatch(updateIsLoading(true))
        WebSocketService.connect('create', username)
    }
    return (
        <Dialog open onClose={() => dispatch(closeModal())}>
            <Paper style={{ padding: 20 }}>
                <Grid container direction="column" justify="center">
                    <Typography variant="h5" style={{ marginBottom: 20 }}>
                        Create game
                    </Typography>
                    <TextField
                        label="Username"
                        value={username}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => dispatch(setUsername(e.target.value))}
                    />
                    <Button
                        disabled={isLoading}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 20 }}
                        onClick={() => createRoom(username)}
                    >
                        Create game
                    </Button>
                </Grid>
            </Paper>
        </Dialog>
    )
}

export default ModalCreateGame
